  .digital-solution-bg {
    background-color: #e9f3ff; /* Light blue background */
  }
  /* Card Styles */
  .custom-digital-card {
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  /* Card Hover Effect */
  .custom-digital-card:hover {
    transform: scale(1.05);
  }
  
  /* Image Styling for Cards */
  .custom-digital-card img {
    border-radius: 10px 10px 0 0;
  }
  
  /* Title Styling */
  .custom-digital-title, .custom-digital-subtitle {
    color: #00154f;
  }
  
  /* Button Link Styling */
  .custom-digital-button {
    text-decoration: none;
    font-weight: bold;
  }
  
  /* Button Hover Effect */
  .custom-digital-button:hover {
    text-decoration: underline;
  }
  