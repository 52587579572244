.about-section {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .timeline {
    display: flex;
    align-items: center;
    margin-bottom: 30px; /* Reduce margin to bring sections closer together */
  }
  
  .timeline-text-col, .timeline-img-col {
    padding: 0 20px; /* Ensure there's consistent padding */
  }
  
  .timeline-text {
    text-align: left;
    max-width: 100%;
  }
  
  .timeline-img {
    width: 100%;
    height: auto;
    max-width: 500px; /* Make the images larger */
    object-fit: cover;
  }
  
  .timeline-center-col {
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .timeline-center {
    position: relative;
    height: 100%;
    width: 2px;
    background-color: #333;
  }
  
  .timeline-center .dot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333;
    transform: translate(-50%, -50%);
  }
  
  /* Remove center line on mobile */
  @media (max-width: 768px) {
    .timeline-center {
      display: none;
    }
  
    .timeline {
      flex-direction: column;
      text-align: center;
    }
  
    .timeline-img {
      margin-bottom: 20px;
    }
  }
  