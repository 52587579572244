.career-form-section {
  background-color: #f9f9f9;
  padding: 3rem 0;
}

.career-form {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.career-form .form-control {
  border-radius: 8px;
}

.career-form .form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.career-form .btn-primary {
  border-radius: 8px;
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s ease;
}

.career-form .btn-primary:hover {
  background-color: #0056b3;
}

.career-form .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
