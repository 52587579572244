.partners-section {
  text-align: center;
}

.partner-logo {
  max-width: 100%;
  height: auto;
}

.uniform-size {
  width: 250px; /* Tüm logoları aynı genişlikte yapmak için */
  height: 250px; /* Sabit yükseklik */
  object-fit: contain; /* Resmin taşma yapmaması ve logo oranlarını koruması için */
}

@media (max-width: 768px) {
  .uniform-size {
    width: 120px; /* Mobil uyumluluk için daha küçük boyut */
    height: 120px;
  }
}
