/* CustomNavbar.css */
.navbar-brand img {
  max-height: 75px;
  object-fit: contain;
}

.navbar-nav .nav-link {
  font-size: 16px;
  margin-right: 15px;
  color: #333; /* Adjust the text color */
}

button.btn-success {
  background-color: #28a745; /* Adjust button color */
  border: none;
}

button.btn-dark {
  background-color: #1c1e21; /* Adjust button color */
  border: none;
}
.dropdown-menu.show {
  z-index: 1000000000 !important;
}
#language-dropdown {
  z-index: 1000 !important; /* Set a high value to ensure it appears above other elements */
}
.dropdown-menu {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;  /* Allow vertical scrolling */
}
/* Top header styling */
.top-header {
  background-color: #f8f9fa; /* Light background for the top header */
}

.top-header .cursor-pointer {
  cursor: pointer; /* Pointer on hover for flags */
}

.top-header img {
  margin-right: 10px; /* Add some space between flags */
}

.trial-button {

  background-color: #198754 !important; /* Adjust button color */
  border: none;
  border: 1px solid #198754 !important;
  color: white !important;
  transition: all 0.3s ease-in-out;
}
.trial-button:hover {
  background-color: #3d3d3d00 !important; /* Adjust button color */
  border: 1px solid #198754 !important;
  color: #198754 !important;
  transition: all 0.3s ease-in-out;
}
/* Ensure responsiveness for mobile */
@media (max-width: 768px) {
  .top-header {
    text-align: center;
    padding: 10px 0;
  }
}
