/* Footer */
.footer {
  position: relative;
  background-color: rgba(255, 255, 255, 0.85); /* Hafif saydam beyaz/krem tonu */
  padding: 40px 0;
  color: #333;
  text-align: start;
  overflow: hidden; /* Canvas taşmalarını önlemek için */
}

.footer-logo {
  width: 120px;
  margin-bottom: 20px;
}

.footer-contact h5,
.footer-links h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #333;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-bottom {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 40px;
}

.footer-bottom p {
  font-size: 14px;
  color: #777;
}

/* Social Icons */
.social-icons {
  margin-top: 10px;
}

.social-icons svg {
  margin-right: 10px;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.social-icons svg:hover {
  color: #007bff;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .footer {
    padding: 20px 0;
  }
  
  .footer-logo {
    width: 100px;
  }

  .footer-contact h5,
  .footer-links h5 {
    font-size: 16px;
  }

  .footer-links li {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .footer-bottom {
    padding-top: 15px;
    margin-top: 20px;
  }

  .footer-bottom p {
    font-size: 12px;
  }
}
.footer-contact a {
  text-decoration: none;
  color: #000;
}