.extra-card {
    transition: all 0.5s ease-in-out;
  }
  
  .solutions-section .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .solutions-section .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
.extra-card-button {
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    color: #333 !important;
  }
  
  .extra-card-button:hover {
    transform: scale(1.05);
  }