.hero-section {
    background-color: #f8f9fa; /* Adjust the background if needed */
    padding: 100px 0; /* Increase padding to make the section taller */
    min-height: 600px; /* Ensure section has enough height */
  }
  
  .text-section h1 {
    font-size: 3rem; /* Make the heading text larger */
    color: #1c1e21; /* Dark color for headings */
    margin-bottom: 20px; /* Increase space between heading and subheading */
    line-height: 1.5; /* Space out the lines more */
    text-align: left;
  }
  
  .text-section h2 {
    font-size: 2rem; /* Adjust subheading size */
    color: #333;
    margin-bottom: 40px; /* Add space between subheading and buttons */
    line-height: 1.5; /* Space out the lines more */
    text-align: left;

  }
  .action-buttons   {
    text-align: left;
  }
  .action-buttons .btn-success {
    padding: 12px 30px; /* Larger buttons */
    font-size: 1.1rem; /* Slightly increase button text size */
  }
  
  .action-buttons .btn-link {
    font-size: 1.1rem;
    text-decoration: none;
    color: #1c1e21;
  }
  
  .image-section img {
    max-width: 100%;
    height: auto;
  }
  .lead {
    text-align: left;
    font-size: 3.2rem;
    font-weight: bold;
  }
  @media screen and (max-width: 378px) {
    .lead {
      text-align: left;
      font-size: 2.2rem;
      font-weight: bold;
    }
  }