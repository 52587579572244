.office-section {
    margin: 3rem 0;
  }
  
  .office-header-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  
  .office-content {
    text-align: left;
    padding: 0 15px;
  }
  
  .office-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .office-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .office-location {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .office-address {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  
  .office-map {
    text-align: right;
    margin-top: 2rem;
  }
  
  .office-map-link {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .office-map-link:hover {
    text-decoration: underline;
  }
  