.blog-section-custom {
    background-color: #f9f9f9;
    padding: 2rem 0;
  }
  
  /* Logo and Blog Header */
  .blog-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog-logo {
    width: 100px; /* Adjust the width of the logo */
    height: auto;
    margin-right: 15px;
  }
  
  .blog-title {
    font-size: 3rem; /* Larger font for the "Blog" text */
    font-weight: 700; /* Make the font bold */
  }
  
  /* Blog Card Styles */
  .blog-card-custom {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .blog-card-custom:hover {
    transform: translateY(-10px);
  }
  
  .blog-card-custom img {
    border-radius: 8px 8px 0 0;
  }
  
  .blog-card-body-custom {
    padding: 1.5rem;
  }
  
  .blog-category-text-custom {
    color: #888;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
  
  .blog-card-title-custom {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }
  
  .blog-card-text-custom {
    font-size: 1rem;
    color: #333;
  }
  
  .blog-read-more-link-custom {
    display: inline-block;
    margin-top: 1rem;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .blog-read-more-link-custom:hover {
    text-decoration: underline;
  }
  