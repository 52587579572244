/* Cookie Consent CSS */
.cookie-consent {
  font-family: Arial, sans-serif;
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: 20px auto;
    width: 95%;
  }
}
