.transformation-section {
    position: relative;
    width: 100%;
    height: 300px; /* Maximum height */
    background-image: url('../assets/banners/altbannerhstbilisim.png'); /* Replace with your background image */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 50px;
    color: white;
  }
  
  .content {
    z-index: 2;
    max-width: 600px;
  }
  
  .small-text {
    font-size: 16px;
    color: #69A0FF; /* Lighter blue for small text */
    margin-bottom: 10px;
  }
  
  .large-text {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.3;
    margin: 0;
    color: white;
  }
  
  .transformation-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    .transformation-section {
      padding: 0 20px;
    }
  
    .large-text {
      font-size: 28px;
    }
  }
  