.services-section {
    background-color: #f8f9fa; /* Light background for contrast */
  }
  
  .service-card {
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
  }
  
  .service-card .text-purple {
    color: #6f42c1; /* Custom purple color for the mobile icon */
  }
  
  .service-card h5 {
    font-weight: bold;
    font-size: 1.25rem; /* Adjust heading size */
    margin-top: 10px;
  }
  
  .service-card p {
    font-size: 0.9rem;
    color: #6c757d; /* Muted color for paragraph text */
  }
  