.contact-form-section {
    background-color: #fff;
    padding: 50px 0;
  }
  
  .contact-info {
    padding-right: 20px;
    text-align: left;
  }
  
  .contact-info h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .contact-info p {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 20px;
  }
  
  .contact-details {
    margin-bottom: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid #dee2e6;
    padding: 15px;
  }
  
  .contact-item svg {
    margin-right: 10px;
  }
  
  .support p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #252525;
  }
  
  .next-steps h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Ensures alignment at the top */
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 20px; /* Add some space between each step */
  }
  
  .steps div {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .steps span {
    font-weight: bold;
    margin-right: 10px;
    background-color: #e6e6e6;
    border-radius: 50%;
    padding: 5px 10px;
    display: inline-block;
  }
  
  .form-section {
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-section h3 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .solution-options {
    display: flex;
    flex-wrap: wrap;
  }
  
  .solution-options .form-check {
    width: 48%;
    margin-bottom: 10px;
  }
  
  button[type="submit"] {
    width: 100%;
    background-color: #28a745;
    border: none;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  .form-label {
    align-items: start;
    display: flex;
  }
  .form-check-label {
    text-align: start;
    align-items: start;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    .next-steps {
      display: none;
    }
  }