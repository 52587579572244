.video-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap; /* Videoların sığmadığı ekranlarda sarılmasını sağla */
}

.video-thumbnail {
  cursor: pointer;
  width: 400px;
}

/* Mobil cihazlar için düzenleme */
@media (max-width: 768px) {
  .video-grid {
    flex-direction: column; /* Videoları alt alta getir */
    align-items: center;    /* Ortaya hizala */
  }

  .video-thumbnail {
    width: 100%; /* Mobilde videoların genişliği tam ekran olsun */
  }
}
