.innovative-solutions-section {
    padding: 50px 0;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #00154f;
  }
  
  .section-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-top: 10px;
  }
  
  .solutions-image {
    margin-top: 30px;
    border-radius: 10px;
  }
  
  .description {
    font-size: 1.1rem;
    color: #333;
    margin-top: 20px;
  }
  