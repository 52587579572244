.custom-faq-section {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.faq-item:hover {
  background-color: #f5f5f5;
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0d1b2a;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, padding 0.4s ease;
  padding: 0;
  color: #333;
  font-size: 1rem;
}

.faq-answer.open {
  max-height: 200px; /* Adjust according to your content */
  padding-top: 0.5rem;
}

.faq-item.active .faq-question {
  color: #0056b3; /* Active question color */
}
